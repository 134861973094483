
@font-face {
    font-family: "LarkBold";
    src: url("./fonts/Lark-Bold_web.woff");
}

@font-face {
    font-family: "LarkLight";
    src: url("./fonts/Lark-Light_web.woff");
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #1B1B1B;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height: 100%;
  width: 100%;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
}

.header {
  display: block;
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.info {
  right: 0;
  left: 0;
  padding: 100px 0 20px;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;

}

a {
  color: black;
  text-decoration: none;
  border-bottom: 2px solid black;
  padding-bottom: 2px;
}

a:hover {
  color: white;
  border-color: white;
}

h1 {
  font-family: "LarkBold";
  font-weight: 100;
  font-size: 2.4em; 
  margin-bottom: 0;
  color: #00AC81;

}

h1 a {
  padding-bottom: 0px;
  border-bottom: 4px solid black;
}

h2 {
  font-family: "LarkLight";
  font-weight: 100;
  font-size: 200%;
  line-height: 1.5;
  margin: 0 auto;
  margin-bottom: 24px;
  color: #00AC81;
}

h3 {
  font-family: "LarkBold";
  font-size: 1.8em;
  font-weight: 100;
  margin-top: 12px;
  margin-bottom: 0;


}

p {
  font-family: "LarkLight";
  font-size: 1.5em;
  margin-top: 0;
}

.subtitle {
  display: none;
}

svg {
  transform: rotate(-15deg);
  height: 40%;
  /*position: fixed;*/
  left: 0;
  /*top: -30%;*/
  display: none;
}


path {
  stroke-dasharray: 1000;
  stroke-dashoffset:1000;
  animation: dash 60s linear;
  animation-iteration-count: infinite;

}

.images {
  width: 100%;
}


li::marker {
  content: "";
}

.col--2x, li:nth-child(even) {
  grid-row-end: span 2;
}
.col--3x {
  grid-row-end: span 3;
}

ul.masonry {
  padding: 0;
  display: grid;
  grid-gap: 4em;
  grid-template-columns: repeat(auto-fill, minmax(400px,1fr));
  grid-auto-rows: 0;
}

.dogdaze:hover {
  /* content:url('./images/dogdaze.gif'); */
}
/* 
.swimming:hover {
  content:url('./images/swimming.gif');
} */

/* 
.palettes:hover {
  content:url('./images/palettes.gif');
} */


/* ul {
  display: flex;
  flex-flow: column wrap;
  list-style: none;
  height: 3600px;
  margin-top: 200px;
  padding-left: 0;
}

li {
  left: 0%;
  width: 30%;
  margin: 0 4% 4% 0;
}
*/

li img {
  width: 100%;
}

/*
li div {
  overflow: visible;
}

li:nth-child(even) {
  left: 32%;
  margin-right: 0;
} */

caption {
  width: 100%;
}

/* CSS */
.image-placeholder {
  /* Set a default size or aspect ratio for the placeholder */
  width: 300px; /* Adjust to your desired default width */
  height: 200px; /* Adjust to your desired default height */
  background-color: #f1f1f1; /* Placeholder background color */
}


@keyframes dash {
  50% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset:-1000;
  }
}

@media only screen and (max-width: 1650px) {
  /* svg {
    top: -40%;
  }

  .info {
    width: 100%;
  }


  ul {
      height: 3200px;

  } */

}

@media only screen and (max-width: 1200px) {
  /* svg {
    top: -10%;
  }

  h1 {
    font-size: 2.4em;
    margin-bottom: 16px;
  }

  h2 {
    line-height: 1.2em;
  }

  .info {
    width: 80%;
  }


  ul {
      height: 2400px;

  } */

}

@media only screen and (max-width: 1000px) {
/* 
  ul {
      height: 2100px;

  } */
}


@media only screen and (max-width: 750px) {
/* .info {
    text-align: left;
    position: relative;
    bottom: 0;
    margin-top: -20px;
    max-width: 85%;
    padding: 100px 0;
    width: 85%;
  }
  ul {
    height: auto;
    margin-top: 100px;
  }

  li {
    width: 100%;
    margin: 0;
  } */
}



@media only screen and (max-width: 500px) {
  /* For mobile phones: */
 
/* 
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.4em;
    line-height: 1.3;
  }

  h3 {
    font-size: 1.4em;
  }

  p {
    font-size: 1.2em;
  }

  svg {
    position:static;
    top: 10%;
  } */

}

